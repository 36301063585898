import React, { useEffect } from 'react';
import {
    Box, Button, Spinner, Stack, Text, BoxProps, DelayedRender, Absolute,
} from 'pws-design-system/design-system';
import usePagination from '../hooks/usePagination';

interface DataListProps extends BoxProps {
    enablePagination?: boolean;
    pageCount?: number;
    pageIndex?: number;
    pageSize?: number;
    totalRows?: number;
    error?: string;
    isLoading?: boolean;
    onPageChange?: (index: number, size: number) => void;
}

const DataList = ({
    children,
    enablePagination = true,
    pageCount: initialPageCount = 10,
    pageIndex: initialPageIndex = 0,
    pageSize: limit = 50,
    totalRows: rowCount = 0,
    error,
    isLoading = false,
    onPageChange = () => {},
    ...rest
}: DataListProps) => {
    const {
        pageIndex,
        pageSize,
        totalRows,
        setTotalRows,
        canPreviousPage,
        canNextPage,
        previousPage,
        nextPage,
    } = usePagination({
        pageIndex: initialPageIndex,
        pageSize: limit,
        pageCount: initialPageCount,
        totalRows: rowCount,
    });

    useEffect(() => {
        onPageChange(pageIndex, pageSize);
    }, [pageIndex]);

    useEffect(() => {
        setTotalRows(rowCount);
    }, [rowCount]);

    return (
        <Box position="relative" {...rest}>
            {error && (
                <Absolute top="0" left="0" right="0" bottom="0">
                    {error}
                </Absolute>
            )}
            <Box opacity={isLoading ? 0.3 : 1}>
                {enablePagination && (
                    <>
                        <Text variant="label" color="text.base.tertiary" fontSize="xs">
                            {pageSize * pageIndex + 1}-
                            {Math.min(pageSize * (pageIndex + 1), totalRows)} / {totalRows} results
                        </Text>
                        <Box height="2px" bg="bg.base.primary" my={2} />
                    </>
                )}

                {children}
                {enablePagination && (
                    <>
                        <Box height="2px" bg="bg.200" />
                        <Stack pt={2} justify="space-between" isInline>
                            <Button
                                size="xs"
                                variant="ghost"
                                variantColor="default"
                                isDisabled={canPreviousPage === false}
                                onClick={() => previousPage()}
                            >
                                Previous
                            </Button>
                            <Button
                                size="xs"
                                variant="ghost"
                                variantColor="default"
                                isDisabled={canNextPage === false}
                                onClick={() => nextPage()}
                            >
                                Next
                            </Button>
                        </Stack>
                    </>
                )}
            </Box>
            {isLoading && (
                <DelayedRender delay={totalRows > 0 ? 100 : 0}>
                    <Box position="absolute" top="0" left="0" right="0" bottom="0">
                        <Spinner
                            size="lg"
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50% -50%)"
                            color="bg.reversed.secondary"
                        />
                    </Box>
                </DelayedRender>
            )}
        </Box>
    );
};

export default DataList;
