import React from 'react';
import { css } from '@emotion/core';
import { DataTable as BaseDataTable } from 'pws-design-system/design-system';
import { DataTableOpts } from 'pws-design-system/design-system/components/table/DataTable';

interface DataTableProps extends DataTableOpts {}

const DataTable = ({ cssOverrides, ...rest }: DataTableProps) => (
    <BaseDataTable
        cssOverrides={css({
            '.table': {
                '.th': {
                    padding: '8px 12px',
                },
                '.td': {
                    border: 0,
                    fontSize: '14px',
                    padding: '12px',
                },
                '.tr:hover': {
                    '.td': {
                        backgroundColor: '#333',
                        cursor: 'pointer',
                    },
                },
                thead: {
                    'tr:last-child': {
                        '.th': {
                            border: 0,
                        },
                    },
                },
                tbody: {
                    'tr:last-child': {
                        '.td': {
                            borderWidth: '2px',
                            borderColor: '#444',
                        },
                    },
                },
            },
        }, cssOverrides)}
        {...rest}
    />
);

export default DataTable;
