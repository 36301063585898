import { useState, useEffect } from 'react';

const usePagination = ({
    pageIndex: initialIndex = 0,
    pageSize: initialPageSize = 25,
    pageCount: initialPageCount = 0,
    totalRows: initialTotal = 0,
}: {
    pageIndex?: number;
    pageSize?: number;
    pageCount?: number;
    totalRows?: number;
} = {}) => {
    const [pageIndex, setPageIndex] = useState(initialIndex);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [pageCount, setPageCount] = useState(initialPageCount);
    const [total, setTotal] = useState(initialTotal);
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const [canNextPage, setCanNextPage] = useState(false);

    const previousPage = () => {
        if (canPreviousPage) {
            setPageIndex(pageIndex - 1);
        }
    };

    const nextPage = () => {
        if (canNextPage) {
            setPageIndex(pageIndex + 1);
        }
    };

    useEffect(() => {
        let lastPageIndex = 0;
        if (total > 0) {
            lastPageIndex = Math.ceil(total / pageSize);
        } else if (pageCount > 0) {
            lastPageIndex = pageCount;
        }
        lastPageIndex -= 1;
        setCanNextPage(pageIndex < lastPageIndex);
        setCanPreviousPage(pageIndex > 0);
    }, [pageIndex]);

    return {
        pageIndex,
        pageSize,
        pageCount,
        totalRows: total,
        setPageIndex,
        setPageSize,
        setPageCount,
        setTotalRows: setTotal,
        canPreviousPage,
        canNextPage,
        previousPage,
        nextPage,
    };
};

export default usePagination;
