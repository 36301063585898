import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box, Button, Icon, Stack } from 'pws-design-system/design-system';
import useDebouncedEffect from 'pws-design-system/design-system/hooks/useDebouncedEffect';
import DashboardLayout from '../layouts/DashboardLayout';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import UsersView from '../views/users/UsersView';
import { UserFilters } from '../../types';
import Users, { User } from '../../models/users/Users';
import useApi, { Api } from '../hooks/api/useApi';
import ProtectedResource from '../ProtectedResource';

interface UserListPageProps {}

const UserListPageContent = () => {
    const [users, setUsers] = useState<Users>(new Users());
    const [pageIndex, setPageIndex] = useState<number>(null);
    const [filters, setFilters] = useState<UserFilters>();

    const { request: getUsers, result, paging, isLoading } = useApi<Users>((api: Api, params: any) => (
        api.routes.users.getUsers(params)
    ));

    const handleAddUser = () => {
        navigate('/users/add');
    };

    const handleSelectUser = (user: User) => {
        navigate(`/user/${user.id}`);
    };

    const handlePageChange = (index: number) => {
        if (index !== pageIndex) {
            setPageIndex(index);
        }
    };

    const handleFiltersChange = (value: UserFilters) => {
        setFilters(value);
    };

    useDebouncedEffect(() => {
        const { query } = filters;
        const params: any = { limit: paging.limit, page: pageIndex + 1 };
        if (query && query.length > 0) {
            params.query = ['username', 'firstName', 'lastName', 'email']
                .map((prop) => `${prop}:^${query}`)
                .join(';');
        }
        getUsers(params);
    }, 500, [filters, pageIndex]);

    useEffect(() => {
        setPageIndex(0);
    }, [filters]);

    useEffect(() => {
        if (result.success) {
            setUsers(result.object);
        } else if (result.error) {
            console.log('Users failed to load', result.error.message);
        }
    }, [result]);

    return (
        <ContentPanelLayout
            title="Users"
            toolbarElement={(
                <Stack spacing={4} align="center" isInline>
                    <Button variantColor="brand-blue" width="44px" height="44px" pl={0} pr={0} aria-label="Add a user" onClick={handleAddUser}>
                        <Icon name="add" size="18px" />
                    </Button>
                </Stack>
            )}
        >
            <Box>
                <UsersView
                    users={users}
                    pagination={result.paging}
                    isLoading={isLoading}
                    onSelectUser={handleSelectUser}
                    onPageChange={handlePageChange}
                    onFiltersChange={handleFiltersChange}
                />
            </Box>
        </ContentPanelLayout>
    );
};

const UserListPage = (props: UserListPageProps & any) => (
    <ProtectedResource>
        <DashboardLayout title="Users" {...props}>
            <UserListPageContent />
        </DashboardLayout>
    </ProtectedResource>
);

export default UserListPage;
