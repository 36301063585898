import React from 'react';
import { Button, Stack, Text, DataTableOpts, Badge } from 'pws-design-system/design-system';
import format from 'date-fns/format';
import { isset } from '@aerisweather/javascript-sdk/dist/utils';
import DataTable from '../../ui/DataTable';
import Users from '../../../models/users/Users';
import { getStatusStyle } from '../../../models/stations/Station';

interface UsersTableViewProps extends DataTableOpts {
    users: Users;
    onSelectRow?: (index: number) => void;
}

const UsersTableView = ({
    users,
    onSelectRow = () => {},
    ...rest
}: UsersTableViewProps) => (
    <DataTable
        data={users.toArray()}
        pageSize={20}
        columns={[{
            title: 'Name',
            field: 'name',
            align: 'left',
            width: 200,
            formatter: (value: any): any => (
                <Text fontSize="md" fontWeight="bold">{value}</Text>
            ),
        }, {
            title: 'Username',
            field: 'username',
            align: 'left',
            width: 200,
        }, {
            title: 'Email',
            field: 'email',
            align: 'left',
            width: 200,
        }, {
            title: 'Registered',
            field: 'registeredAt',
            formatter: (value: any) => {
                if (isset(value)) {
                    return format(new Date(value), 'MMM d, yyyy');
                }
                return 'N/A';
            },
        }, {
            title: 'Status',
            field: 'status',
            width: 100,
            formatter: (value: any): any => {
                const { style, color } = getStatusStyle(value);
                return (
                    <Badge variant={style} variantColor={color}>{value}</Badge>
                );
            },
        }, {
            title: 'Stations',
            field: 'totalStations',
            width: 200,
            formatter: (value: any) => value,
        }, {
            id: 'actions',
            width: 100,
            formatter: (value: any, cell: any): any => (
                <Stack justify="flex-end" isInline>
                    <Button
                        size="xs"
                        variant="solid"
                        variantColor="default"
                        onClick={() => handleSelectRow(cell.row)}
                    >
                        Edit
                    </Button>
                </Stack>
            ),
        }]}
        itemSize={46}
        onRowClick={(data: any) => onSelectRow(data.index)}
        enablePagination
        manualPagination
        manualSearch
        {...rest}
    />
);

export default UsersTableView;
