import React, { useRef, useState, useEffect } from 'react';
import {
    Hide, Icon, Stack, TextInput, BoxProps,
} from 'pws-design-system/design-system';
import useResizeAware from 'pws-design-system/design-system/hooks/useResizeAware';
import UsersListView from './UsersListView';
import Users, { User } from '../../../models/users/Users';
import { UserFilters } from '../../../types';
import UsersTableView from './UsersTableView';
import PagingInfo from '../../../api/PagingInfo';
import { Helmet } from 'react-helmet';
interface UserListViewProps extends BoxProps {
    users: Users;
    pagination?: PagingInfo;
    error?: any;
    isLoading?: boolean;
    includeToolbar?: boolean;
    onSelectUser?: (user: User) => void;
    onPageChange?: (index: number, size: number) => void;
    onFiltersChange?: (filters: UserFilters) => void;
}

const UserListView = ({
    users,
    pagination,
    error,
    isLoading = false,
    includeToolbar = true,
    onSelectUser = () => {},
    onPageChange = () => {},
    onFiltersChange = () => {},
}: UserListViewProps) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [filters, setFilters] = useState<UserFilters>({});
    const containerRef = useRef();
    const size = useResizeAware(containerRef);

    const handleSelectRow = (index: number) => {
        const user = users.at(index);
        onSelectUser(user);
    };

    const handlePageChange = (index: number, pageSize: number) => {
        setPageIndex(index);
        onPageChange(index, pageSize);
    };

    const updateFilter = (key: string, value: any) => {
        setPageIndex(0);
        setFilters((prevValue) => {
            const newValue = { ...prevValue, [key]: value };
            return newValue;
        });
    };

    useEffect(() => {
        onFiltersChange(filters);
    }, [filters]);

    return (
        <>
            <Helmet>
                <title>Users | PWSWeather</title>
            </Helmet>
            <div ref={containerRef}>
                <Hide tablet desktop>
                    {includeToolbar && (
                        <Stack mb={3}>
                            <TextInput
                                size="md"
                                variant="flushed"
                                placeholder="Search..."
                                leftElement={<Icon name="search-2" color="text.base.tertiary" />}
                                onChange={(e: any) => {
                                    updateFilter('query', e.target.value);
                                }}
                            />
                        </Stack>
                    )}
                    <UsersListView
                        users={users}
                        listSize={{ width: size.width || 300, height: 500 }}
                        error={error}
                        isLoading={isLoading}
                        onPageChange={handlePageChange}
                        onSelectCell={(index) => handleSelectRow(index)}
                    />
                </Hide>
                <Hide mobile>
                    {includeToolbar && (
                        <Stack mb={3} align="baseline" isInline>
                            <TextInput
                                size="md"
                                variant="flushed"
                                placeholder="Search..."
                                leftElement={<Icon name="search-2" color="text.base.tertiary" />}
                                onChange={(e: any) => {
                                    updateFilter('query', e.target.value);
                                }}
                            />
                        </Stack>
                    )}
                    <UsersTableView
                        users={users}
                        error={error}
                        isLoading={isLoading}
                        onSelectRow={handleSelectRow}
                        onPageChange={handlePageChange}
                        pageIndex={pageIndex}
                        pageSize={pagination.limit}
                        pageCount={pagination.totalPages}
                        totalRows={pagination.total}
                        fillAdjust={-98}
                        fill
                        virtualize
                    />
                </Hide>
            </div>
        </>
    );
};

export default UserListView;
