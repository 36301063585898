import React from 'react';
import { Box, Icon, List, Stack, Text, BoxProps, Badge } from 'pws-design-system/design-system';
import Users, { User } from '../../../models/users/Users';
import DataList from '../../ui/DataList';
import { getStatusStyle } from '../../../models/stations/Station';

const CELL_HEIGHT = 57;

const UserCell = ({
    user,
    style,
    onClick = () => {},
}: { user: User; style: any; onClick: () => void; }) => {
    const { color: statusColor, style: statusStyle } = getStatusStyle(user.status);
    return (
        <div style={style}>
            <Stack px={2} py={2} justify="space-between" align="center" cursor="pointer" isInline onClick={onClick}>
                <Stack spacing={1} maxWidth="60%" flexGrow={1}>
                    <Text fontSize="md" fontWeight="bold">{user.name}</Text>
                    <Text fontSize="xs" color="text.base.tertiary">{user.totalStations} station{user.totalStations !== 1 ? 's' : ''}</Text>
                </Stack>
                <Stack align="center" isInline>
                    <Stack align="flex-end" spacing={1}>
                        <Badge variant={statusStyle} variantColor={statusColor}>{user.status}</Badge>
                    </Stack>
                    <Box>
                        <Icon name="chevron-right" color="bg.500" size="24px" mr={-2} />
                    </Box>
                </Stack>
            </Stack>
            <Box height="1px" bg="bg.100" />
        </div>
    );
};

interface UsersListViewProps extends BoxProps {
    users: Users;
    error?: any;
    pageSize?: number;
    listSize?: { width: number, height: number };
    isLoading?: boolean;
    onPageChange?: (index: number, size: number) => void;
    onSelectCell?: (index: number) => void;
}

const UsersListView = ({
    users,
    error,
    pageSize = 20,
    listSize = { width: 300, height: 500 },
    isLoading = false,
    onPageChange = () => {},
    onSelectCell = () => {},
    ...rest
}: UsersListViewProps) => (
    <DataList
        pageSize={pageSize}
        totalRows={users ? users.count : 0}
        error={error}
        isLoading={isLoading}
        onPageChange={onPageChange}
        {...rest}
    >
        <List
            cellProvider={({ index, style }) => (
                <UserCell user={users.at(index)} onClick={() => onSelectCell(index)} style={style} />
            )}
            itemCount={users ? users.count : 0}
            itemSize={CELL_HEIGHT}
            width={listSize.width}
            height={listSize.height}
        />
    </DataList>
);

export default UsersListView;
